import advies from "../resources/icons/3.png";
import DropdownFAQ from "../components/DropdownFAQ";
import Consult from "../resources/services/consult/consult.png";
import RoundBulletPoint from "../components/RoundBulletPoint";

function InfoAdvice() {
  return (
    <div className="mx-4 md:mx-16 xl:mx-0">
      <div className="xl:grid lg:grid-cols-2">
        <div className="mx-auto mt-16 xl:col-span-1">
          <img className="w-[125px] xl:w-[200px] mx-auto" src={advies} alt="" />
          <h1 className=" py-4 font-bold text-[30px] md:text-[32px] lg:text-[36px] text-center text-transparent bg-clip-text bg-gradient-to-l from-adhocDonkerBlauw to-adhocBlauw">
            Cybersecurity
            <br />
            Consultancy
          </h1>
        </div>

        <div className="mt-6 xl:col-span-1 xl:w-4/6">
          <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px]">
            Waarom een Cybersecurity Consult?
          </h1>
          <p className="md:px-16 text-[16px] md:text-[18px] leading-8 pt-4">
            In het huidige tijdperk is het beheersen van cybersecurity risico’s
            essentieel voor het voortbestaan van een organisatie. Een
            cyberaanval kan aanzienlijke gevolgen hebben voor een bedrijf, niet
            alleen op financieel vlak, maar ook wat betreft reputatie en
            mogelijke juridische repercussies. Een cybersecurity consultant
            helpt klanten de risico’s in kaart te brengen en biedt passende
            maatregelen om deze te beheersen. Het inschakelen van een
            cybersecurity consultant zorgt voor tal van voordelen, waaronder
            toegang tot expertise, objectiviteit en kostenbesparing.
          </p>
        </div>
      </div>

      <div className="pb-10 mx-auto mt-8 md:px-20 md:mt-10 lg:mt-12 xl:mt-20 xl:w-5/6 rounded-xl">
        <div className="mx-auto xl:w-4/5">
          <h1 className="text-center text-slate-700 text-[24px] lg:text-[30px] pb-4">
            Hoe gaan wij te werk?
          </h1>
          <p className="text-[16px] md:text-[18px] leading-8">
            Het proces omvat het grondig analyseren van de naleving van
            regelgeving, het identificeren van kwetsbaarheden in de bescherming
            van vertrouwelijke gegevens, het vergroten van het
            beveiligingsbewustzijn en het beoordelen en beheren van risico's met
            behulp van een Risk Analysis Matrix. Op basis van deze audit kan Ad
            Hoc IT Services gerichte adviezen en strategieën bieden om de
            cybersecurity van de klant te verbeteren.
          </p>
        </div>

        <div className="flex justify-center my-8 rounded-md xl:my-12">
          <img
            className="object-cover rounded-md lg:h-72 lg:w-4/5"
            src={Consult}
            alt=""
          />
        </div>

        <div className="mx-auto xl:w-4/5">
          <DropdownFAQ
            title="Compliance en regelgeving"
            text={
              <>
                <RoundBulletPoint text="Identificatie van relevante cybersecurity-regelgeving en compliance-vereisten." />
                <RoundBulletPoint text="Beoordeling van de huidige naleving van regelgeving en identificatie van afwijkingen." />
                <RoundBulletPoint text="Ontwikkelen van een actieplan om aan de vereisten te voldoen en compliant te blijven." />
              </>
            }
          />
          <DropdownFAQ
            title="Bescherming van vertrouwelijke gegevens"
            text={
              <>
                <RoundBulletPoint text="Inventarisatie van vertrouwelijke gegevens en identificatie van de juiste beveiligingsmaatregelen." />
                <RoundBulletPoint text="Evaluatie van bestaande beveiligingsmechanismen en identificatie van mogelijke zwakke punten." />
                <RoundBulletPoint text="Implementatie van geavanceerde beveiligingsoplossingen, zoals encryptie, toegangscontrole en gegevensclassificatie." />
              </>
            }
          />
          <DropdownFAQ
            title="Bewustwording en training"
            text={
              <>
                <RoundBulletPoint text="Evaluatie van het huidige beveiligingsbewustzijn binnen de organisatie." />
                <RoundBulletPoint text="Ontwikkeling van bewustwordingsprogramma's en trainingen op maat." />
                <RoundBulletPoint text="Implementatie van continue educatie en periodieke beoordelingen van het beveiligingsbewustzijn." />
              </>
            }
          />
          <DropdownFAQ
            title="Opstellen van een Risk Analysis Matrix"
            text={
              <>
                <RoundBulletPoint text="Identificatie van potentiële bedreigingen en kwetsbaarheden." />
                <RoundBulletPoint text="Classificatie en beoordeling van risico's op basis van impact en waarschijnlijkheid." />
                <RoundBulletPoint text="Prioritering van risico's en ontwikkeling van een actieplan voor risicobeheer." />
              </>
            }
          />
        </div>
        <div className="mx-auto my-12 xl:w-4/5">
          <h1 className="text-center text-slate-700 text-[24px] lg:text-[30px] pb-4">
            Wat gaat dit kosten?
          </h1>
          <p className="text-[16px] md:text-[18px] leading-8">
            Om een gedetailleerd kostenplaatje te kunnen geven zal één van onze
            consultants vooraf vrijblijvend een kennismakingsgesprek inplannen.
            De kosten voor cybersecurity-audits kunnen variëren afhankelijk van
            verschillende factoren en de specifieke behoeften van elke klant.
            Het is raadzaam om contact op te nemen met Ad Hoc IT Services voor
            een offerte op maat.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InfoAdvice;
