import servicedesk from "../resources/icons/2.png";
import CheckBulletPoint from "../components/checkBulletPoint";

function InfoServicedesk() {
  return (
        <div className="mx-4 sm:mx-16 xl:mx-0">
          <div className="xl:grid lg:grid-cols-2">
            <div className="mx-auto mt-16 xl:col-span-1">
              <img
                className="w-[125px] xl:w-[200px] mx-auto"
                src={servicedesk}
                alt=""
              />
              <h1 className=" py-4 font-bold text-[30px] md:text-[32px] lg:text-[36px] text-center text-transparent bg-clip-text bg-gradient-to-l from-adhocDonkerBlauw to-adhocBlauw">
                ICT Servicedesk
              </h1>
            </div>

            <div className="my-auto xl:col-span-1 xl:w-4/6">
              <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px]">
                Over onze servicedeskmedewerkers
              </h1>
              <p className="px-4 md:px-16 text-[16px] md:text-[18px] leading-8 pt-4">
                De servicedesk medewerkers bij Ad Hoc IT zijn ITIL
                gecertificeerd, hoogopgeleid en volledig ingewerkt. Onze
                professionals werken onsite en remote, ook buiten kantoortijden.
                Ze hebben kennis van hardware-netwerktechniek, Windows 10,
                Microsoft Azure AD, Office 365, ticketsystemen en vele andere
                software en applicaties. Ook zijn ze getraind door onze
                cybersecurity professionals in cyber threat awareness.
              </p>
            </div>
          </div>
          <div className="my-8 xl:py-10 rounded-xl">
            <div className="p-4 mx-auto bg-white shadow-lg xl:w-8/12 md:p-14 rounded-xl">
              <h1 className="text-center text-slate-700 text-[24px] lg:text-[30px]">
                Werkzaamheden van een servicedeskmedewerker
              </h1>
                <div className="grid-cols-2 md:mt-4 lg:mx-14 lg:grid">
                  <CheckBulletPoint text="Incidenten (vragen en verzoeken) aannemen, registreren (ticketsystemen), analyseren, volgen en afhandelen." />
                  <CheckBulletPoint text="Samenwerken met een team van servicedeskmedewerkers en systeem- en netwerkbeheerders." />
                  <CheckBulletPoint text="Incidenten overdragen aan een (interne of externe) helpdesk of andere verantwoordelijke afdeling." />
                  <CheckBulletPoint text="Beheren en onderhouden van informatiesystemen." />
                  <CheckBulletPoint text="(Eind)gebruikers ondersteunen, telefonisch, per e-mail of instant messaging." />
                  <CheckBulletPoint text="Beveiliging van kleine tot middelgrote netwerken." />
                  <CheckBulletPoint
                    text="Procesmatig werken aan de hand van beschreven kaders en standaardbeschrijvingen (ITIL, Service Level Agreement, SLA)."
                    conditionalBorder={true}
                  />
                  <CheckBulletPoint
                    text="Testprocedures opstellen en uitvoeren."
                    noBorder={true}
                  />
                </div>
            </div>
          </div>
        </div>
  );
}

export default InfoServicedesk;
