import webapp from "../resources/icons/1.png";
import DropdownFAQ from "../components/DropdownFAQ";
import vscode from "../resources/services/software/vscode.png";
import developer from "../resources/services/software/developer.png";

function InfoSoftwareDevelopment() {
  return (
    <>
        <div className="mx-4 mb-16 sm:mx-16 xl:mx-0">
          <div className="xl:grid lg:grid-cols-2">
            <div className="mx-auto mt-16 xl:col-span-1">
              <img
                className="w-[125px] xl:w-[200px] mx-auto"
                src={webapp}
                alt=""
              />
              <h1 className=" py-4 font-bold text-[30px] md:text-[32px] lg:text-[36px] text-center text-transparent bg-clip-text bg-gradient-to-l from-adhocDonkerBlauw to-adhocBlauw">
                Software development
              </h1>
            </div>

            <div className="my-auto xl:col-span-1 xl:w-4/6">
              <div className="py-6">
                <h1 className="text-center font-bold text-slate-700 text-[24px] lg:text-[30px]">
                  Zakelijke website of app laten maken
                </h1>
                <p className="px-4 md:px-16 text-[16px] md:text-[18px] leading-8 pt-4">
                  Bent u op zoek naar een website of App die functioneel is én
                  voor veel aanvragen zorgt? Ad Hoc IT Services ontwerpt,
                  ontwikkelt en onderhoudt zakelijke websites. Daarnaast werken
                  wij samen met developers over heel Europa.
                </p>
              </div>
            </div>
          </div>

          <div className="grid xl:grid xl:grid-cols-2">
            <div className="col-span-1 xl:order-2">
                <div className="mx-auto lg:mt-16 xl:ml-10 xl:relative">
                  <img
                    className="xl:absolute xl:right-28 rounded-md w-96 xl:w-[60%] hidden xl:flex"
                    src={vscode}
                    alt=""
                  />
                  <img
                    className="xl:absolute xl:top-40 rounded-md mx-auto max-w-[95%] md:max-w-[70%] xl:max-w-none xl:w-[60%]"
                    src={developer}
                    alt=""
                  />
                </div>
            </div>

            <div className="col-span-1 mt-8 xl:mx-20 xl:mt-12">
              <DropdownFAQ
                title="Waarom een zakelijke website of app op maat laten maken?"
                text={
                  <p className="px-4">
                    Een zakelijke website hebben is belangrijk omdat je website
                    ook het middelpunt van de online aanwezigheid van je bedrijf
                    is. Zakelijke websites kunnen uiteraard simpel en plain maar
                    als je de lat hoog legt, je website voor je wilt laten
                    werken, geen concessies wilt doen en wilt dat je website
                    naadloos bij je branding aansluit, dan is een maatwerk
                    website precies wat je nodig hebt.
                  </p>
                }
              />
              <DropdownFAQ
                title="Hoe gaan wij te werk?"
                text={
                  <p className="px-4">
                    Zodra u het aanvraagformulier invult met alle wensen en
                    benodigdheden voor de website kunnen wij een offerte
                    opstellen samen met een plan van aanpak en een timeline van
                    wat er gaat gebeuren en wanneer. Zodra er een akkoord wordt
                    gegeven gaan wij onmiddellijk aan de slag.
                  </p>
                }
              />
              <DropdownFAQ
                title="Hoeveel kost het?"
                text={
                  <p className="px-4">
                    Het budget dat u beschikbaar moet stellen voor een maatwerk
                    website of App is sterk afhankelijk van de grootte van de
                    website, complexiteit, functionaliteiten en koppelingen met
                    externe software. Doorgaans begint de investering nabij de €
                    2000.-
                  </p>
                }
              />
              <DropdownFAQ
                title="Hoe lang duurt het om mijn website of app te laten maken?"
                text={
                  <p className="px-4">
                    De timeline is sterk afhankelijk van de omvang van het
                    project. Over het algemeen worden websites in 2 tot 6
                    maanden gemaakt. In de meeste gevallen kunnen wij de
                    deadline verkorten naar uw gewenste datum.
                  </p>
                }
              />
              <DropdownFAQ
                title="Hoe zit het met het onderhoud en beheer van de website of app?"
                text={
                  <p className="px-4">
                    Na de lancering van de zakelijke website of app zijn wij
                    verantwoordelijk voor de onderhoud en beheer. Dit doen wij
                    samen met een partner van Ad hoc IT services die inmiddels
                    al jaren ervaring hebben.
                  </p>
                }
              />
            </div>
          </div>
        </div>
    </>
  );
}

export default InfoSoftwareDevelopment;
