import webapp from "../resources/icons/1.png";
import servicedesk from "../resources/icons/2.png";
import advies from "../resources/icons/3.png";
import ServiceCard from "./ServiceCard";

function Promo() {
  return (
    <div className="text-center">
      <div className="justify-center w-full px-4 pb-4 rounded-b rounded-xl bg-adhocBlauw sm:pb-14">
        <h1 className="text-white text-[24px] lg:text-[30px] py-8 sm:py-14">
          Onze services
        </h1>
        <div className="items-stretch gap-8 lg:flex md:justify-center">
          <ServiceCard
            pic={webapp}
            title="Software development"
            description="Wij ontwikkelen zakelijke websites en Apps voor onze klanten,
                bekijk de mogelijkheden!"
            link="/services/software-ontwikkelen"
          />
          <ServiceCard
            pic={servicedesk}
            title="ICT Servicedesk"
            description="Wij ontzorgen u van alle 1e lijns ICT taken, hierdoor heeft uw organisatie tijd om zich te richten op kernzaken."
            link="/services/ict-servicedesk"
          />
          <ServiceCard
            pic={advies}
            title="Cybersecurity Consultancy"
            description="Onze consultants zijn gespecialiseerd in het leveren van hoogwaardige cybersecurity adviezen op maat."
            link="/services/professioneel-advies"
          />
        </div>
      </div>
    </div>
  );
}

export default Promo;
