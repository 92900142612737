import Ali from "../resources/over/ali.png";
import Justin from "../resources/over/justin.png";
import Persoon from "../components/Persoon";
import Over from "../resources/over/over.jpeg";

function OverOns() {
  return (
      <div>
        <div className="flex m-4 sm:mb-16 lg:gap-20 xl:mx-0">
          <div className="lg:w-[45%] lg:ml-auto text-[16px] md:text-[18px] leading-8 p-4">
            <h1 className="font-bold text-[30px] md:text-[32px] lg:text-[36px] text-adhocBlauw text-center md:text-left mb-4">
              Over Ad Hoc
            </h1>
            <p>
              Ad Hoc IT Services is in essentie een cybersecurity bedrijf dat
              gespecialiseerd is in het leveren van hoogwaardige cybersecurity
              oplossingen. Ook leveren wij servicedesk diensten en software
              ontwikkeling. We streven naar een holistische aanpak, waarbij we
              de nadruk leggen op cyberveiligheid, effectieve communicatie met
              onze klanten en efficiëntie in onze dienstverlening. Deze
              kernwaarden definiëren wie we zijn en waar we voor staan.
            </p>
            <p className="pt-4">
              We geloven in het leveren van oplossingen die aansluiten bij de
              unieke behoeften van elke klant. We streven ernaar om op een
              effectieve en gestroomlijnde manier te werken, zodat we onze
              klanten tijd en kosten kunnen besparen.
            </p>
            <p className="pt-4">
              Wat ons onderscheidt, is onze warme en toegankelijke
              bedrijfscultuur. We creëren een omgeving waarin ons team nauw
              samenwerkt en elkaar ondersteunt. We koesteren onze kennis en
              ervaring en moedigen een continu leerproces aan, zodat we altijd
              up-to-date blijven met de nieuwste ontwikkelingen op het gebied
              van cybersecurity. Kortom, Met ons ervaren en hechte IT-team
              streven we ernaar om hoogwaardige diensten te leveren en onze
              klanten te helpen bij het beveiligen van hun digitale omgeving.
            </p>
          </div>

          <div className="hidden col-span-1 my-auto mr-auto lg:block">
            <img className="w-[340px] rounded-md  h-fit" src={Over} alt="" />
          </div>
        </div>

        <div className="px-4 py-10 bg-adhocBlauw rounded-t-xl xl:px-0">
          <h1 className="text-center text-white text-[24px] lg:text-[30px] mb-10">
            Maak kennis met ons team
          </h1>

          <div className="flex flex-col gap-12 mx-auto w-full">
            <Persoon
              pic={Ali}
              name="Ali Jirrie"
              function="Oprichter Ad Hoc IT Services"
              link="https://www.linkedin.com/in/ali-jirrie-b35995205/"
              text={
                <>
                  Mijn naam is Ali, ik ben de oprichter van Ad Hoc IT Services.
                  <br />
                  Ik ben een sportfanaat met een sterke interesse in de
                  ontwikkelingen op het gebied van cybersecurity. Ik heb een
                  masteropleiding gevolgd in Cybersecurity Governance, waarbij
                  de focus lag op het oplossen van vraagstukken rondom
                  cyberveiligheid.
                  <br />
                  <br />
                  Alhoewel ik 24 jaar oud ben, heb ik al veel ervaring opgedaan
                  bij verschillende bedrijven en organisaties. Ook heb ik 4 jaar
                  bij een groot ICT-bedrijf gewerkt, wat uiteindelijk mij
                  inspireerde om voor mezelf te beginnen. Ik voelde me namelijk
                  niet op mijn plek bij andere bedrijven omdat mijn ambities en
                  enthousiasme werden beperkt. Ikzelf ben namelijk een
                  aanpakker, ik steek liefst mijn handen uit de mouwen en pak
                  uitdagingen vol overtuiging aan.
                </>
              }
            />

            <Persoon
              pic={Justin}
              name="
            Justin Azekraoui"
              function="Skilled Servicedesk Medewerker"
              link="https://www.linkedin.com/in/justin-azekraoui/"
              text={
                <>
                  Mijn naam is Justin. Ik ben een gedreven 23 jarige
                  life-science student met een passie voor IT en alles wat erbij
                  komt kijken. Deze passie heb ik weten te vervullen met mijn
                  baan als servicedesk medewerker bij Ad Hoc IT services. Verder
                  krijg ik bij Ad Hoc IT de ruimte en vrijheid om uitdagingen
                  creatief aan te pakken.
                  <br />
                  <br />
                  Vanuit mijn studie krijg ik ook dagelijks te maken met de IT,
                  echter wel op gebruikersniveau. Dit geeft mij een uniek beeld
                  over de wensen van de klant en wat er voor nodig is om deze te
                  vervullen. In het verleden ben ik al meerdere malen tegen de
                  barrière in communicatie tussen de IT afdeling en
                  eindgebruikers gelopen, Bij Ad Hoc IT fungeer ik eerder als
                  een schakel tussen de afdelingen en wordt mijn bijdrage
                  gewaardeerd. Deze fijne werksfeer motiveert en inspireert mij
                  iedere dag weer om het beste resultaat te behalen.
                </>
              }
            />
          </div>
        </div>
      </div>
  );
}

export default OverOns;
